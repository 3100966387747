import React, { useEffect } from 'react'
import { Layout, Stack, Main } from '@layout'
import { graphql } from 'gatsby'
import { Card as CardComponent } from 'theme-ui'
import $ from 'jquery'

import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import FontAwesome from '../components/FontAwesome'
import MarkdownBody from '../components/MarkdownBody'

const styles = {
  body: {
    '& td': {
      minWidth: '9.375rem',
      '&:first-of-type': {
        textAlign: ['left', null, 'center']
      }
    },
    '& table, & tr:hover td': {
      color: 'text'
    }
  }
}

export default function Post({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, thumbnail }
    }
  },
  ...props
}) {
  useEffect(() => {
    const popovers = $('[data-toggle="popover"]')
    popovers.popover()
  }, [])

  return (
    <Layout {...props}>
      <FontAwesome />
      <Seo
        siteUrl={process.env.GATSBY_SITE_URL}
        title={title}
        thumbnail={thumbnail}
      />
      <Stack>
        <Main>
          <Divider />
          <CardComponent variant='paper-lg'>
            <MarkdownBody html={html} rootStyles={styles.body} />
          </CardComponent>
        </Main>
      </Stack>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($pagePath: String!) {
    markdownRemark(frontmatter: { slug: { eq: $pagePath } }) {
      html
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
