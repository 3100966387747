import React from 'react'
import { Box } from 'theme-ui'

import '../utils/bootstrap-imports'
import '../styles/cryptocoin-purified.css'

const styles = {
  body: {
    wordBreak: 'break-word',
    '& .btn-primary': {
      borderColor: '#233140',
      backgroundColor: '#2c3e50',
      color: '#ccc',
      backgroundImage: 'none'
    }
  }
}

export default function MarkdownBody({ html, rootStyles = {} }) {
  const fullRootStyles = { ...rootStyles, ...styles.body }
  return <Box dangerouslySetInnerHTML={{ __html: html }} sx={fullRootStyles} />
}
