import React from 'react'
import Helmet from 'react-helmet'

export default function FontAwesome() {
  return (
    <Helmet>
      <script
        src='https://kit.fontawesome.com/8ba28194a3.js'
        crossorigin='anonymous'
      ></script>
    </Helmet>
  )
}
